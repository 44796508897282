import Vue from 'vue'
import router from './router'
import {globalMixin} from '@/globalMixin';
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './axios';
import store from './store'
import VueMask from 'v-mask'

Vue.config.productionTip = false
Vue.mixin(globalMixin)
Vue.use(VueMask)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
