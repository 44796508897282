import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import pt from 'vuetify/es5/locale/pt'

export default new Vuetify({
    lang: {
        locales : {pt},
        current : 'pt'
    },
    theme : {
        themes : {
            light: {
                primary : '#023449',
                secondary: '#E12318', // #FFCDD2
                accent: '#0E61bf' // #3F51B5
            }
        }
    },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
});
