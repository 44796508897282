<template>
    <v-app id="inspire">
        <template v-if="logado">
            <LoginDialog v-if="usuario.flagprimeiroacesso" />
            <DialogTermos v-if="!usuario.aceitepolitica"/>
            <v-navigation-drawer v-model="drawer" fixed>
                <v-row>
                    <v-col cols="12" class="text-center mt-3 pb-0">
                        <v-avatar color="accent" size="48">
                            <span
                                style="color: #FFF;"
                                class="font-weight-bold"
                            >{{usuario.nome.substr(0,1)}}</span>
                        </v-avatar>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <h3 class="my-3">{{ usuario.nome }}</h3>
                    </v-col>
                </v-row>
                <v-list nav dense>
                    <v-list-item-group
                        v-model="group"
                        active-class="deep-primary--text text--accent-4"
                    >
                        <v-list-item v-for="m in menuPrincipal" :key="m.link" :to="m.to">
                            <v-icon left dense class="mb-1 mr-5">{{m.icon}}</v-icon>
                            <v-list-item-title>{{m.titulo}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item to="/perfil">
                            <v-icon left top dense class="mb-1 mr-5">mdi-account-circle</v-icon>
                            <v-list-item-title>Meu perfil</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                <v-row>
                    <v-col class="text-center mt-3">
                        <v-btn
                            color="primary"
                            rounded
                            class="font-weight-bold"
                            to="/login/sair"
                        >Sair</v-btn>
                    </v-col>
                </v-row>
            </v-navigation-drawer>

            <v-app-bar height="62" app color="primary" dark flat elevation="2">
                    <v-toolbar-title>
                        <router-link to="/">
                            <v-img max-height="40" max-width="220" src="./assets/icons/logo.png" />
                        </router-link>
                    </v-toolbar-title>

                    <v-spacer />

                    <v-btn
                        class="hidden-sm-and-down"
                        v-for="m in menuPrincipal"
                        :key="m.link"
                        :to="m.to"
                        color="white"
                        text
                    >
                    <!-- <v-icon left>{{m.icon}}</v-icon> -->
                    {{m.titulo}}</v-btn>
                    <Avatar />

                    <v-app-bar-nav-icon @click="drawer = true" class="hidden-md-and-up"></v-app-bar-nav-icon>
            </v-app-bar>

            <v-main class="grey lighten-4">
<!--                <v-container class="mb-sm-0">-->
<!--                  <div style="width: 100%; border: solid 1px red; height: 300px"></div>-->
                    <keep-alive>
                        <router-view />
                    </keep-alive>
<!--                </v-container>-->
              <div style="text-align: center;" class="py-3">
                <p class="py-0 my-0">
                  <img height="70" src="./assets/icons/logo_footer.svg" />
                </p>
              </div>
            </v-main>

        </template>
      <template v-else>
        <v-main
            class="d-none d-md-block"
            style="background-size: auto 120%; background-position: center; overflow: hidden; background-repeat: repeat-x;"
            :style="`background-image: url(${require(`@/assets/bglogin.jpg`)});`"
        >
            <v-container class="fill-height">
                <Login />
            </v-container>
            <div
                class="primary white--text text-center pa-1"
                style="position: absolute; bottom:0; width: 100%;"
            >
              Essa plataforma é destinada para clientes e parceiros da Comercial Ivaiporã Ltda.
            </div>
        </v-main>
        <v-main
            class="d-md-none"
            style="background-size: auto 120%; background-position: center; overflow: hidden; background-repeat: repeat-x;"
            :style="`background-image: url(${require(`@/assets/bglogin.jpg`)});`"
        >
          <v-container class="fill-height">
            <Login />
          </v-container>
          <div
              class="primary white--text text-center pa-1"
              style="position: absolute; bottom:0; width: 100%;"
          >
            Essa plataforma é destinada para clientes e parceiros da Comercial Ivaiporã Ltda.
          </div>
<!--          <div-->
<!--              class="primary white&#45;&#45;text text-center pa-1"-->
<!--              style="position: absolute; bottom:0; width: 100%;"-->
<!--          >-->
<!--            Essa plataforma é destinada a clientes de nossas lojas físicas com compras para entrega futura, para acessar o seu cadastro na loja virtual,-->
<!--            <a-->
<!--                class="text-decoration-none white&#45;&#45;text"-->
<!--                href="https://www.comercialivaipora.com.br/"-->
<!--            >clique aqui</a>.-->
<!--          </div>-->
        </v-main>
      </template>
    </v-app>
</template>

<script>
import Login from "./Components/Login/Login";
import Avatar from "./Components/Widgets/Avatar";
import LoginDialog from "./Components/Login/LoginDialog";
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import DialogTermos from './Components/Widgets/DialogTermos.vue';

export default {
    components: { Login, Avatar, LoginDialog , DialogTermos },
    data: () => ({
        menuPrincipal: [],
        drawer: false,
        group: null,
    }),
    computed: {
        ...mapState(["usuario", "sessaoChave", "backendUrl", "logado"]),
    },
    methods: {
        ...mapMutations(["uLogar"]),
        async validarToken() {
            const json = localStorage.getItem(this.sessaoChave);
            const sessao = JSON.parse(json);

            if (!sessao) {
                this.$store.commit("uLogar", null);
                return this.$router.push("/login");
            }

            await axios
                .post(`${this.backendUrl}login/sessao_token_validar`, {
                    token: sessao.token,
                })
                .then((res) => {
                    if (res.data) {
                        this.$store.commit("uLogar", {
                            ...sessao,
                            logado: true,
                        });
                    } else {
                        localStorage.removeItem(this.sessaoChave);
                        return this.$router.push("/login");
                    }
                });
        },
        montarMenu() {
            let menu = [
                // { titulo: "Entrega Futura", to: "/" , icon: 'mdi-truck-delivery'},
                // {titulo : 'Meus Contratos', to : '/contrato', icon: 'mdi-file-document-outline'},
                // {titulo : 'Minhas Entregas', to : '/entrega', icon: 'mdi-human-dolly'}
            ];

            this.menuPrincipal = menu;
        },
        async init() {
            await this.validarToken();

            if (this.logado) {
                this.montarMenu();
            }
        },
    },
    created() {
        this.init();
    },
    watch: {
        logado: function () {
            this.init();
        },
    },
};
</script>

<style>
</style>