export const globalMixin = {
    data() {
        return {

        }
    },
    methods: {
        erroFormatar(e) {
            return e.response.data.erro.split(';').join("<br />")
        },
        permissao(perm) {
            let usuarioSessao = JSON.parse(localStorage.getItem('comercialivaipora_gestor'))
            return usuarioSessao.permissao.filter((p) => {
                return p.idpermissao == perm
            }).length > 0
        }
    }
};