<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="7" class="d-none d-sm-block">
                <img height="1400px" style="position: absolute; bottom:-650px; left:50px;" src="../../assets/mestre.png" />
            </v-col>
            <v-col cols="12" sm="8" md="5" class="px-4 d-none d-sm-block">
                <v-row justify="center">
                    <v-col cols="12">
                        <v-img
                            style="margin: 0 auto;"
                            max-height="100"
                            max-width="300"
                            src="../../assets/icons/logo_dark.svg"
                        ></v-img>
                    </v-col>
                    <v-col cols="12">
                        <v-container class="text-xs-center">
                            <v-card flat class="rounded-lg" :loading="carregando" elevation="5">
                                <v-card-title primary-title>
                                    <h4 v-if="!recuperarSenha && !novaSenha">Login</h4>
                                    <h4 v-if="recuperarSenha && !novaSenha">Recuperar senha</h4>
                                    <h4 v-if="novaSenha">Nova senha</h4>
                                </v-card-title>
                                <v-divider></v-divider>
                                <template v-if="!novaSenha">
                                    <v-form v-if="!recuperarSenha">
                                        <v-card-text>
                                            <v-text-field
                                                v-model="dados.usuario"
                                                name="usuario"
                                                @keydown.enter="logar()"
                                                label="Usuário"
                                            />
                                            <v-text-field
                                                v-model="dados.senha"
                                                name="senha"
                                                @keydown.enter="logar()"
                                                label="Senha"
                                                type="password"
                                                autocomplete="off"
                                            />
                                            <v-alert
                                                v-if="erro"
                                                dismissible
                                                icon="mdi-alert-circle-outline"
                                                close-icon="mdi-close"
                                                type="error"
                                                class="mb-0"
                                            >{{erro}}</v-alert>
                                        </v-card-text>
                                        <v-card-actions class="pb-4 pr-4">
                                            <v-spacer />
                                            <!--<v-btn :disabled="carregando" text class="font-weight-bold" @click="recuperarSenha = true, erro = ''">Recuperar Senha</v-btn>-->
                                            <v-btn
                                                :loading="carregando"
                                                color="primary"
                                                class="font-weight-bold"
                                                @click="logar()"
                                            >Entrar</v-btn>
                                        </v-card-actions>
                                    </v-form>
                                    <v-form v-else>
                                        <v-card-text>
                                            <v-text-field
                                                v-model="dados.usuario"
                                                name="cpf"
                                                label="CPF, Usuário ou E-mail"
                                            />
                                            <v-alert
                                                v-if="erro"
                                                dismissible
                                                icon="mdi-alert-circle-outline"
                                                close-icon="mdi-close"
                                                type="error"
                                                class="mb-0"
                                            >{{erro}}</v-alert>
                                        </v-card-text>
                                        <v-card-actions class="pb-4 pr-4">
                                            <v-spacer />
                                            <v-btn
                                                :disabled="carregando"
                                                text
                                                class="font-weight-bold"
                                                @click="recuperarSenha = false, erro = ''"
                                            >Voltar para o Login</v-btn>
                                            <v-btn
                                                :loading="carregando"
                                                color="primary"
                                                class="font-weight-bold"
                                                @click="recuperarSenhaUsuario()"
                                            >Recuperar</v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </template>
                                <template v-else>
                                    <v-form>
                                        <v-card-text>
                                            <v-text-field
                                                v-model="dados.senha"
                                                name="senha"
                                                @keydown.enter="logar()"
                                                label="Senha"
                                                type="password"
                                                autocomplete="off"
                                            />
                                            <v-text-field
                                                v-model="dados.senha"
                                                name="confirmarSenha"
                                                @keydown.enter="logar()"
                                                label="Confirmar senha"
                                                type="password"
                                                autocomplete="off"
                                            />
                                            <v-alert
                                                v-if="erro"
                                                dismissible
                                                icon="mdi-alert-circle-outline"
                                                close-icon="mdi-close"
                                                type="error"
                                                class="mb-0"
                                            >{{erro}}</v-alert>
                                        </v-card-text>
                                        <v-card-actions class="pb-4 pr-4">
                                            <v-spacer />
                                            <v-btn
                                                :disabled="carregando"
                                                text
                                                class="font-weight-bold"
                                                @click="recuperarSenha = false, novaSenha = false, erro = ''"
                                            >Voltar para o Login</v-btn>
                                            <v-btn
                                                :loading="carregando"
                                                color="primary"
                                                class="font-weight-bold"
                                                @click="novaSenhaUsuario()"
                                            >Entrar</v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </template>
                            </v-card>
                        </v-container>
                    </v-col>
                </v-row>
            </v-col>

<!--            <v-col cols="12" md="7" class="d-sm-none">-->
<!--              <img style="position: absolute; top:0; left:38px;" height="600" src="../../assets/mestre.png" />-->
<!--            </v-col>-->
            <v-col cols="12" sm="8" md="5" class="px-4 d-sm-none">
              <v-row justify="center">
                <v-col cols="12">
                  <v-img
                      style="margin: 0 auto;"
                      max-height="100"
                      max-width="300"
                      src="../../assets/icons/logo_dark.svg"
                  ></v-img>
                </v-col>
                <v-col cols="12">
                  <v-container class="text-xs-center">
                    <v-card flat class="rounded-lg" :loading="carregando" elevation="5">
                      <v-card-title primary-title>
                        <h4 v-if="!recuperarSenha && !novaSenha">Login</h4>
                        <h4 v-if="recuperarSenha && !novaSenha">Recuperar senha</h4>
                        <h4 v-if="novaSenha">Nova senha</h4>
                      </v-card-title>
                      <v-divider></v-divider>
                      <template v-if="!novaSenha">
                        <v-form v-if="!recuperarSenha">
                          <v-card-text>
                            <v-text-field
                                v-model="dados.usuario"
                                name="usuario"
                                @keydown.enter="logar()"
                                label="Usuário"
                            />
                            <v-text-field
                                v-model="dados.senha"
                                name="senha"
                                @keydown.enter="logar()"
                                label="Senha"
                                type="password"
                                autocomplete="off"
                            />
                            <v-alert
                                v-if="erro"
                                dismissible
                                icon="mdi-alert-circle-outline"
                                close-icon="mdi-close"
                                type="error"
                                class="mb-0"
                            >{{erro}}</v-alert>
                          </v-card-text>
                          <v-card-actions class="pb-4 pr-4">
                            <v-spacer />
                            <!--<v-btn :disabled="carregando" text class="font-weight-bold" @click="recuperarSenha = true, erro = ''">Recuperar Senha</v-btn>-->
                            <v-btn
                                :loading="carregando"
                                color="primary"
                                class="font-weight-bold"
                                @click="logar()"
                            >Entrar</v-btn>
                          </v-card-actions>
                        </v-form>
                        <v-form v-else>
                          <v-card-text>
                            <v-text-field
                                v-model="dados.usuario"
                                name="cpf"
                                label="CPF, Usuário ou E-mail"
                            />
                            <v-alert
                                v-if="erro"
                                dismissible
                                icon="mdi-alert-circle-outline"
                                close-icon="mdi-close"
                                type="error"
                                class="mb-0"
                            >{{erro}}</v-alert>
                          </v-card-text>
                          <v-card-actions class="pb-4 pr-4">
                            <v-spacer />
                            <v-btn
                                :disabled="carregando"
                                text
                                class="font-weight-bold"
                                @click="recuperarSenha = false, erro = ''"
                            >Voltar para o Login</v-btn>
                            <v-btn
                                :loading="carregando"
                                color="primary"
                                class="font-weight-bold"
                                @click="recuperarSenhaUsuario()"
                            >Recuperar</v-btn>
                          </v-card-actions>
                        </v-form>
                      </template>
                      <template v-else>
                        <v-form>
                          <v-card-text>
                            <v-text-field
                                v-model="dados.senha"
                                name="senha"
                                @keydown.enter="logar()"
                                label="Senha"
                                type="password"
                                autocomplete="off"
                            />
                            <v-text-field
                                v-model="dados.senha"
                                name="confirmarSenha"
                                @keydown.enter="logar()"
                                label="Confirmar senha"
                                type="password"
                                autocomplete="off"
                            />
                            <v-alert
                                v-if="erro"
                                dismissible
                                icon="mdi-alert-circle-outline"
                                close-icon="mdi-close"
                                type="error"
                                class="mb-0"
                            >{{erro}}</v-alert>
                          </v-card-text>
                          <v-card-actions class="pb-4 pr-4">
                            <v-spacer />
                            <v-btn
                                :disabled="carregando"
                                text
                                class="font-weight-bold"
                                @click="recuperarSenha = false, novaSenha = false, erro = ''"
                            >Voltar para o Login</v-btn>
                            <v-btn
                                :loading="carregando"
                                color="primary"
                                class="font-weight-bold"
                                @click="novaSenhaUsuario()"
                            >Entrar</v-btn>
                          </v-card-actions>
                        </v-form>
                      </template>
                    </v-card>
                  </v-container>
                </v-col>
              </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import axios from "axios";

export default {
    name: "Login",
    data: () => ({
        carregando: false,
        erro: "",
        dados: {},
        recuperarSenha: false,
        novaSenha: false,
    }),
    computed: {
        ...mapState(["backendUrl", "sessaoChave"]),
    },
    methods: {
        ...mapMutations(["login"]),
        logar() {
            this.carregando = true;

            axios
                .post(`${this.backendUrl}login`, this.dados)
                .then((res) => {
                    this.erro = null;
                    this.carregando = false;
                    this.dados = {};

                    this.$store.commit("uLogar", { ...res.data });
                    localStorage.setItem(
                        this.sessaoChave,
                        JSON.stringify(res.data)
                    );

                    this.erro = null;
                    this.carregando = false;
                    this.$router.push("/");
                })
                .catch((e) => {
                    this.erro = this.erroFormatar(e);
                    this.carregando = false;
                });
        },
        recuperarSenhaUsuario() {
            this.novaSenha = true;
        },
        novaSenhaUsuario() {},
    },
};
</script>

<style scoped>
</style>