<template>
    <v-dialog persistent v-model="dialogV" fullscreen>
      <v-card class="d-md-none">
          <v-toolbar-title class="primary align-center text-center align-content-center justify-center center" style="height:62px" >
              <v-img max-height="40" max-width="220" class="mx-auto" src="@/assets/icons/logo.png" />
          </v-toolbar-title>
          <v-card-title>
              <span class="text-h6">Política de Privacidade e Proteção de dados</span>
          </v-card-title>
          <v-col cols="12" class="py-0">
              <v-alert
                  colored-border
                  text
                  type="info"
                  class="mx-3 text-justify texto-dialog"
              >Leia nossa Política de Privacidade e Proteção de dados e ao final confirme a leitura e o consentimento sobre o uso dos dados</v-alert>
          </v-col>
          <v-card-text class="pb-0 custom-card-text">
              <p class="text-justify">
                  Nós, da <strong>COMERCIAL IVAIPORÃ LTDA</strong> ("Comercial Ivaiporã", "nós"), prezamos por uma relação de transparência e confiança com quem nos relacionamos, tanto no ambiente digital quanto
                  em nossas unidades físicas, em atenção à legislação aplicável, em especial, a Lei Geral de Proteçãode Dados - Lei nº 13.709/2018 e Marco Civil da Internet - Lei nº 12.965/2014.
              </p>

              <p class="text-justify">
                  Nesta Política de Privacidade e Proteção de Dados ("Política"), vamos informar e esclarecer sobre como utilizamos e coletamos os dados pessoais dos nossos clientes, usuários desse site, parceiros,
                  fornecedores, candidatos a vagas de trabalho, participantes das nossas campanhas promocionais, usuários do clube de vantagens ("Você"), bem como a finalidade de cada atividade de tratamento de dados,
                  com quem os compartilhamos e como Você pode exercer o controle sobre eles.
              </p>

              <p class="text-justify">
                  Recomendamos que esta Política seja lida com atenção. Caso tenha qualquer dúvida sobre o conteúdo desta Política, entre em contato diretamente com nosso Encarregado de Dados, por meio do e-mail:
                  <a href="mailto:compliancelgpd@comercialivaipora.com.br"><em>compliancelgpd@comercialivaipora.com.br</em></a>.
              </p>

              <p><strong>1.&nbsp;GLOSSÁRIO</strong></p>

              <p class="text-justify">
                  <strong>Dados Pessoais</strong>: informações relacionadas a uma pessoa natural (identificada ou identificável), incluindo números identificativos, dados de localização, identificadores eletrônicos,
                  ou qualquer informação que, quando combinada com outras informações, seja capaz de identificar alguém e torná-la identificável;
              </p>

              <p class="text-justify">
                  <strong>Tratamento de dados:</strong> é qualquer tipo de atividade baseada em Dados Pessoais, incluindo, por exemplo, a coleta, registro, armazenamento, organização consulta, uso, divulgação,
                  compartilhamento, transmissão, classificação, reprodução, processamento e avaliação;
              </p>

              <p class="text-justify">
                  <strong>Cookies</strong>: pequenos arquivos armazenados no dispositivo do Usuário, a fim de identificar a sua conta, obter dados de navegabilidade, avaliar a eficácia da nossa comunicação e publicidade,
                  e personalizar sua experiência junto à Comercial Ivaiporã.
              </p>

              <p><strong>2.&nbsp;QUAIS DADOS PESSOAIS PODEM SER COLETADOS E PARA QUAIS FINALIDADES?</strong></p>

              <p class="text-justify">
                  A Comercial Ivaiporã pode realizar o tratamento dos seus Dados Pessoais a depender da interação que Você tiver com nossos Serviços, a exemplo de algumas atividades indicadas abaixo:
              </p>
              <div style="overflow-x:auto;">
                  <table
                      border="1"
                      cellpadding="0"
                      cellspacing="0"
                  >

                  <thead>
                      <tr>
                          <th>ATIVIDADE</th>
                          <th>TIPOS DE DADOS COLETADOS</th>
                          <th>FINALIDADE</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td><p class="text-center">Conta no Site e compras online</p></td>
                          <td><p class="text-center"><u>Dados de cadastro</u>: e-mail, CPF, nome completo, sexo, data de nascimento e endereço.<br /><br /><u>Histórico de compras.</u></p></td>
                          <td><p class="text-center">Processamento de compras, devolução e troca de produtos, realização de parcelamentos, realização de entregas, inscrição em programas de fidelidade,
                              emissão de nota fiscal, cumprimento de obrigação legal, entre outros.
                          </p></td>
                      </tr>
                      <tr>
                          <td><p class="text-center">Compras pelas lojas físicas</p></td>
                          <td><p class="text-center"><u>Dados de cadastro</u>: nome, telefone e CPF e outras informações (a depender se outro serviço for contratado, como por exemplo: crediário e empréstimo pessoal).</p></td>
                          <td><p class="text-center">Processamento de compras, devolução e troca de produtos, realização de parcelamentos, realização de entregas, inscrição em programas de fidelidade,
                              emissão de nota fiscal, cumprimento de obrigação legal, entre outros.
                          </p></td>
                      </tr>
                      <tr>
                          <td><p class="text-center">Funcionamento do Site</p></td>
                          <td><p class="text-center">
                              <u>Dados de navegação</u>: dados coletados por meio de cookies ou device IDs, incluindo IP, data e hora de acesso, localização geográfica, tipo de navegador, duração da visita e páginas visitadas.
                          </p>
                          <p class="text-center">
                              <u>Dados sobre o dispositivo de acesso:</u> modelo, fabricante, sistema operacional, operadora de telefonia, tipo de navegador, tipo e velocidade da conexão.
                          </p></td>
                          <td><p class="text-center">
                              Viabilizar o funcionamento adequado do site, armazenar suas preferências, permitir uma boa experiência de uso e cumprir com as nossas obrigações legais.
                          </p></td>
                      </tr>
                      <tr>
                          <td><p class="text-center"><em>Analytics</em></p></td>
                          <td><p class="text-center">
                              <u>Dados de navegação</u>: dados coletados por meio de cookies ou device IDs, incluindo IP, data e hora de acesso, localização geográfica, tipo de navegador, duração da visita e páginas visitadas.
                          </p></td>
                          <td><p class="text-center">
                              Entender seu comportamento de navegação e como o site/aplicativo está sendo usado, para melhorar sua experiência como usuário. Os dados coletados são agregados e, sempre que possível,
                              anonimizados.<a href="https://www.comercialivaipora.com.br/#_msocom_1">[UG1]</a>
                          </p></td>
                      </tr>
                      <tr>
                          <td><p class="text-center">Envio de ofertas e publicidade</p></td>
                          <td><p class="text-center"><u>Dados de cadastro:</u> nome, e-mail e telefone.</p></td>
                          <td><p class="text-center">Direcionamento de ofertas e campanhas promocionais.</p></td>
                      </tr>
                      <tr>
                          <td><p class="text-center">Comunicação com o cliente</p></td>
                          <td><p class="text-center"><u>Dados de cadastro:</u> nome, e-mail e telefone.</p></td>
                          <td><p class="text-center">Oferecimento e divulgação de novos serviços e parcerias que possam ser de interesse do cliente.</p></td>
                      </tr>
                      <tr>
                          <td><p class="text-center">Clube de Fidelização</p></td>
                          <td><p class="text-center"><u>Dados de cadastro:</u> e-mail, nome, telefone, CPF, data de nascimento e endereço.</p></td>
                          <td><p class="text-center">Cadastro no programa de fidelidade da Comercial Ivaiporã.</p></td>
                      </tr>
                      <tr>
                          <td><p class="text-center">Ações promocionais</p></td>
                          <td><p class="text-center"><u>Dados de cadastro</u>: Nome, CPF, endereço, e-mail e telefone.</p></td>
                          <td><p class="text-center">Entrar em contato com o ganhador do prêmio.</p></td>
                      </tr>
                      <tr>
                          <td><p class="text-center">Segurança</p></td>
                          <td><p class="text-center">Captação de imagem por câmeras de segurança.</p></td>
                          <td><p class="text-center">Segurança física dos ambientes da Comercial Ivaiporã.</p></td>
                      </tr>
                      <tr>
                          <td><p class="text-center">Canais de atendimento</p></td>
                          <td><p class="text-center">Nesse processo, os dados coletados por nós variam caso o Cliente já seja cadastrado em nossos sistemas - situação em que é solicitado o CPF - ou se ele está fazendo um novo contato.
                              Nesta última hipótese, os dados tratados, em regra, são: nome completo, CPF, endereço,telefone e e-mail.</p></td>
                          <td><p class="text-center">Atender às solicitações, reclamações, sugestões ou elogios dos Clientes.</p></td>
                      </tr>
                      <tr>
                          <td><p class="text-center">Recrutamento e seleção de pessoal</p></td>
                          <td><p class="text-center"><u>Dados de currículo</u>: nome, endereço, telefone, e-mail, histórico acadêmico, histórico profissional, competências, línguas entre outros que Você espontaneamente nos fornecer.</p></td>
                          <td><p class="text-center">Recrutamento de novos integrantes, incluindo o contato por telefone ou e-mail para viabilizar a participação nos nossos processos seletivos.</p></td>
                      </tr>
                      <tr>
                          <td><p class="text-center">Relacionamento com fornecedores e parceiros</p></td>
                          <td><p class="text-center"><u>Dados de cadastro</u>: nome, endereço, telefone, e-mail, dados de documentos, inscrição no INSS, entre outros.</p></td>
                          <td><p class="text-center">Gerenciamento de relacionamento comercial e operacionalização dos pagamentos aos nossos parceiros e fornecedores.</p></td>
                      </tr>
                  </tbody>
                  </table>
              </div>

              <p/><p class="text-justify">
                  Você será o único responsável pela precisão, veracidade e atualização dos Dados Pessoais que nos fornecer.
              </p>

              <p><strong>3.&nbsp;COM QUEM OS DADOS PESSOAIS SÃO COMPARTILHADOS</strong></p>

              <p class="text-justify">
                  Os seus Dados Pessoais são muito importantes para nós, pois são parte essencial do nosso negócio - o qual, frisa-se, não é a venda de informações para terceiros. Compartilhamos os Dados
                  Pessoais nas situações descritas a seguir:
              </p>

              <p class="text-justify">
                  <strong>&bull; Parceiros e prestadores de serviços</strong>. Nós contratamos empresas e pessoas para realizarem determinados serviços para a sua melhor experiência conosco. Na medida do possível, sempre
                  buscamos avaliar cuidadosamente nossos fornecedores e parceiros e firmar com eles obrigações contratuais de segurança da informação e proteção de Dados Pessoais. Quando Você utiliza os serviços de nossos
                  parceiros (Empréstimo Pessoal, Seguros Prestamista, Programa de Fidelidade, dentre outros), Você também se submete às regras e políticas de privacidade das respectivas empresas.
              </p>

              <p class="text-justify">
                  <strong>&bull; Autoridades públicas.</strong> Podemos compartilhar os Dados Pessoais com autoridades públicas que os solicite, inclusive com órgãos de fiscalização das atividades laborais ou de atividades
                  financeiras, órgãos reguladores, órgãos do Poder Judiciário ou Autoridades Policiais.
              </p>

              <p class="text-justify">
                  <strong>&bull; Transferência de negócios.</strong> Nós estamos constantemente desenvolvendo nosso negócio, de forma que podemos vender ou comprar subsidiárias, novas unidades de negócio e ativos em
                  geral, a qualquer momento. Nestas operações, é comum que as informações e dados dos clientes façam parte dos ativos transferidos, desde que expressamente respeitadas as garantias desta Política.
              </p>

              <p class="text-justify">
                  <strong>&bull; Para terceiros indicados por Você.</strong> Podemos compartilhar seus Dados Pessoais com terceiros que tenham sido indicados por Você, mediante a sua solicitação.
              </p>

              <p><strong>4.&nbsp;TRANSFERÊNCIA INTERNACIONAL DE DADOS</strong></p>

              <p class="text-justify">
                  Quando utilizarmos serviços de hospedagem em nuvem localizados em outros países os seus Dados Pessoais poderão ser transferidos internacionalmente.
              </p>

              <p><strong>5.&nbsp;PRAZO DE ARMAZENAMENTO</strong></p>

              <p class="text-justify">
                  Os seus Dados Pessoais serão armazenados pela Comercial Ivaiporã somente pelo tempo que for necessário para cumprir com as finalidades para as quais os coletamos, inclusive para fins de cumprimento
                  de quaisquer obrigações legais, contratuais, de prestação de contas ou requisição de autoridades competentes.
              </p>

              <p class="text-justify">
                  Os prazos são definidos pela Comercial Ivaiporã de acordo com as características específicas de cada tratamento, incluindo a natureza dos dados, as finalidades para o tratamento, as obrigações legais ou
                  regulatórias existentes, entre outros critérios.
              </p>


              <p><strong>6.&nbsp;COOKIES</strong></p>

              <p class="text-justify">
                  Para customizar a experiência dos usuários e para gerar informações relevantes sobre a forma de uso deste site, a Comercial Ivaiporã poderá fazer o uso dos seguintes Cookies:
              </p>

              <p class="text-justify">
                  <strong>&bull; Cookies estritamente necessários</strong> para viabilizar o funcionamento adequado do site;
              </p>

              <p class="text-justify">
                  <strong>&bull; Cookies preferenciais</strong> para armazenar suas preferências e permitir uma boa experiência de uso;
              </p>

              <p class="text-justify">
                  <strong>&bull; Cookies de análise</strong> para melhorar a qualidade do site por meio da coleta de informações sobre quais páginas são mais acessadas, as páginas pouco acessadas
                  e tempo de permanência no site, por exemplo.
              </p>

              <p class="text-justify">
                  A maioria dos navegadores de internet contém informações sobre como rejeitar novos cookies, como receber notificações quando receber um novo cookie ou até mesmo como desabilitá-los.
                  Ao desabilitar, bloquear ou de qualquer forma rejeitar os cookies, pode ser que Você não consiga utilizar determinadas funções de nosso site, principalmente as que exigem que Você esteja
                  logado em sua Conta Comercial Ivaiporã.
              </p>

              <p class="text-justify">
                  &bull; Como remover cookies no <a href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&amp;hl=pt-BR" target="_blank">Google Chrome</a>
              </p>

              <p class="text-justify">
                  &bull; Como remover cookies no <a href="https://support.mozilla.org/pt-BR/kb/limpe-cookies-e-dados-de-sites-no-firefox" target="_blank">Mozilla Firefox</a>
              </p>

              <p class="text-justify">
                  &bull; Como remover cookies no <a href="https://support.microsoft.com/pt-br/help/278835/how-to-delete-cookie-files-in-internet-explorer" target="_blank">Internet Explorer</a>
              </p>

              <p class="text-justify">
                  &bull; Como remover cookies no <a href="https://support.apple.com/pt-br/HT201265" target="_blank">Safari</a><a href="https://www.comercialivaipora.com.br/#_msocom_2">[UG2]</a>
              </p>


              <p><strong>7.&nbsp;COMO NÓS PROTEGEMOS OS SEUS DADOS</strong></p>

              <p class="text-justify">
                  A Comercial Ivaiporã adota medidas técnicas e administrativas, pautadas nas boas práticas do mercado, para proteger os seus Dados Pessoais contra acessos não autorizados, perda, roubo,
                  divulgação,alteração e qualquer outra modalidade de tratamento inadequado ou indevido.
              </p>

              <p class="text-justify">
                  Todos os Dados Pessoais coletados serão armazenados em ambiente seguro, observado o estado da técnica disponível e criptografados. No entanto, considerando que nenhum sistema de segurança
                  é absolutamente seguro, nós infelizmente não podemos garantir que não ocorrerão apreensões, interceptações e/ou violações nos nossos sistemas e bases de dados, e, por isso, a Comercial
                  Ivaiporã se exime de quaisquer responsabilidades por eventuais danos e/ou prejuízos decorrentes de falhas, vírus ou invasões.
              </p>

              <p><strong>8.&nbsp;VOCÊ NO CONTROLE DOS SEUS DADOS - CONHEÇA OS SEUS DIREITOS</strong></p>

              <p class="text-justify">
                  A Comercial Ivaiporã está comprometida com o cumprimento dos direitos que a legislação concede à Você, na qualidade de titular de Dados Pessoais. Abaixo, indicaremos quais são eles e como Você pode
                  exercê-los junto ao Comercial Ivaiporã, a qualquer tempo:
              </p>

              <p class="text-justify">
                  <strong>&bull; Confirmação</strong> da existência de atividades de tratamento de Dados Pessoais;
              </p>

              <p><strong>&bull; Acesso</strong> aos Dados Pessoais;</p>

              <p class="text-justify">
                  <strong>&bull; Retificação</strong> de Dados Pessoais que estejam incompletos, inexatos ou desatualizados;
              </p>

              <p class="text-justify">
                  <strong>&bull; Anonimização, bloqueio ou exclusão</strong >, nos casos em que forem tratados dados desnecessários, excessivos ou em desconformidade com a legislação;
              </p>

              <p class="text-justify">
                  <strong>&bull; Portabilidade</strong> dos Dados Pessoais direto à Você ou a terceiros que escolher;
              </p>

              <p class="text-justify">
                  <strong>&bull; Exclusão de dados tratados com consentimento</strong>, nos casos em que essa for a base legal para uma determinada atividade de tratamento, ressalvadas as possibilidades de
                  continuarmos armazenando os dados quando for necessário para o cumprimento de uma obrigação legal ou regulatória, ou para exercício regular dos direitos da Comercial Ivaiporã;
              </p>

              <p class="text-justify">
                  <strong>&bull; Revogação de consentimento</strong>, para quando Você tiver nos fornecido consentimento. Porém, essa revogação não afetará a legalidade das atividades realizadas até então;
              </p>

              <p class="text-justify">
                  <strong>&bull; Oposição ao tratamento</strong>, nos casos em que o consentimento não for a base legal aplicável ao tratamento dos Dados Pessoais ou quando houver algum descumprimento à
                  legislação. A Comercial Ivaiporã avaliará se a sua oposição é justificada ou não e adotará as medidas necessárias para suspender o tratamento, quando cabível e viável.
              </p>

              <p class="text-justify">
                  Para exercê-los, basta enviar a sua solicitação para o e-mail <a href="mailto:compliancelgpd@comercialivaipora.com.br"><em>compliancelgpd@comercialivaipora.com.br</em></a><em>.</em>
              </p>

              <p class="text-justify">
                  Atenderemos às suas solicitações dentro de um prazo razoável, em conformidade com a legislação aplicável, lembrando que, em alguns casos, poderemos te responder apenas após confirmarmos a sua
                  identidade.
              </p>

              <p class="text-justify">
                  A fim de garantir sua segurança e evitar fraudes, a Comercial Ivaiporã poderá solicitar informações ou comprovações necessárias para que seus Dados Pessoais não sejam compartilhados com quem não tenha
                  autorização para tanto.
              </p>

              <p><strong>9.&nbsp;CONTATO</strong></p>


              <p class="text-justify">
                  Em caso de qualquer dúvida em relação a esta
                  Política ou solicitações para o cumprimento de
                  seus direitos, Você ou seu responsável legal
                  poderão entrar em contato pelo e-mail <a
                  href="mailto:compliancelgpd@comercialivaipora.com.br"
                  ><em>compliancelgpd@comercialivaipora.com.br</em></a
                  ><em>.</em>
              </p>

              <p><strong>10.&nbsp;ALTERAÇÕES DA POLÍTICA</strong></p>

              <p class="text-justify">
                  Nós buscamos oferecer os nossos Serviços com
                  excelência e com a maior eficiência possível. Por
                  isso, esta Política pode ser atualizada a qualquer tempo,
                  cabendo à Você verificá-la sempre que
                  possível por meio deste site.
              </p>
          </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-col cols="12" class="py-3">
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  class="py-1"
              >
                <v-btn width="100%" small class="btn-lg btn-block" outlined text color="red" to="/login/sair">
                  Recusar termos e sair do sistema
                </v-btn>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  class="py-1"
              >
                <v-btn width="100%" small class="btn-lg btn-block" color="success" @click="confirmarPolitica()">
                  declaro que li e aceito os termos
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-card-actions>
      </v-card>
      <v-card class="d-none d-md-block">
        <v-toolbar-title class="primary align-center text-center align-content-center justify-center center" style="height:62px" >
          <v-img max-height="40" max-width="220" class="mx-auto" src="@/assets/icons/logo.png" />
        </v-toolbar-title>
        <v-card-title>
          <span class="text-h6">Política de Privacidade e Proteção de dados</span>
        </v-card-title>
        <v-col cols="12" class="py-0">
          <v-alert
              colored-border
              text
              type="info"
              class="mx-3 text-justify texto-dialog"
          >Leia nossa Política de Privacidade e Proteção de dados e ao final confirme a leitura e o consentimento sobre o uso dos dados</v-alert>
        </v-col>
        <v-card-text class="pb-0 custom-card-text2">
          <p class="text-justify">
            Nós, da <strong>COMERCIAL IVAIPORÃ LTDA</strong> ("Comercial Ivaiporã", "nós"), prezamos por uma relação de transparência e confiança com quem nos relacionamos, tanto no ambiente digital quanto
            em nossas unidades físicas, em atenção à legislação aplicável, em especial, a Lei Geral de Proteçãode Dados - Lei nº 13.709/2018 e Marco Civil da Internet - Lei nº 12.965/2014.
          </p>

          <p class="text-justify">
            Nesta Política de Privacidade e Proteção de Dados ("Política"), vamos informar e esclarecer sobre como utilizamos e coletamos os dados pessoais dos nossos clientes, usuários desse site, parceiros,
            fornecedores, candidatos a vagas de trabalho, participantes das nossas campanhas promocionais, usuários do clube de vantagens ("Você"), bem como a finalidade de cada atividade de tratamento de dados,
            com quem os compartilhamos e como Você pode exercer o controle sobre eles.
          </p>

          <p class="text-justify">
            Recomendamos que esta Política seja lida com atenção. Caso tenha qualquer dúvida sobre o conteúdo desta Política, entre em contato diretamente com nosso Encarregado de Dados, por meio do e-mail:
            <a href="mailto:compliancelgpd@comercialivaipora.com.br"><em>compliancelgpd@comercialivaipora.com.br</em></a>.
          </p>

          <p><strong>1.&nbsp;GLOSSÁRIO</strong></p>

          <p class="text-justify">
            <strong>Dados Pessoais</strong>: informações relacionadas a uma pessoa natural (identificada ou identificável), incluindo números identificativos, dados de localização, identificadores eletrônicos,
            ou qualquer informação que, quando combinada com outras informações, seja capaz de identificar alguém e torná-la identificável;
          </p>

          <p class="text-justify">
            <strong>Tratamento de dados:</strong> é qualquer tipo de atividade baseada em Dados Pessoais, incluindo, por exemplo, a coleta, registro, armazenamento, organização consulta, uso, divulgação,
            compartilhamento, transmissão, classificação, reprodução, processamento e avaliação;
          </p>

          <p class="text-justify">
            <strong>Cookies</strong>: pequenos arquivos armazenados no dispositivo do Usuário, a fim de identificar a sua conta, obter dados de navegabilidade, avaliar a eficácia da nossa comunicação e publicidade,
            e personalizar sua experiência junto à Comercial Ivaiporã.
          </p>

          <p><strong>2.&nbsp;QUAIS DADOS PESSOAIS PODEM SER COLETADOS E PARA QUAIS FINALIDADES?</strong></p>

          <p class="text-justify">
            A Comercial Ivaiporã pode realizar o tratamento dos seus Dados Pessoais a depender da interação que Você tiver com nossos Serviços, a exemplo de algumas atividades indicadas abaixo:
          </p>
          <div style="overflow-x:auto;">
            <table
                border="1"
                cellpadding="0"
                cellspacing="0"
            >

              <thead>
              <tr>
                <th>ATIVIDADE</th>
                <th>TIPOS DE DADOS COLETADOS</th>
                <th>FINALIDADE</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td><p class="text-center">Conta no Site e compras online</p></td>
                <td><p class="text-center"><u>Dados de cadastro</u>: e-mail, CPF, nome completo, sexo, data de nascimento e endereço.<br /><br /><u>Histórico de compras.</u></p></td>
                <td><p class="text-center">Processamento de compras, devolução e troca de produtos, realização de parcelamentos, realização de entregas, inscrição em programas de fidelidade,
                  emissão de nota fiscal, cumprimento de obrigação legal, entre outros.
                </p></td>
              </tr>
              <tr>
                <td><p class="text-center">Compras pelas lojas físicas</p></td>
                <td><p class="text-center"><u>Dados de cadastro</u>: nome, telefone e CPF e outras informações (a depender se outro serviço for contratado, como por exemplo: crediário e empréstimo pessoal).</p></td>
                <td><p class="text-center">Processamento de compras, devolução e troca de produtos, realização de parcelamentos, realização de entregas, inscrição em programas de fidelidade,
                  emissão de nota fiscal, cumprimento de obrigação legal, entre outros.
                </p></td>
              </tr>
              <tr>
                <td><p class="text-center">Funcionamento do Site</p></td>
                <td><p class="text-center">
                  <u>Dados de navegação</u>: dados coletados por meio de cookies ou device IDs, incluindo IP, data e hora de acesso, localização geográfica, tipo de navegador, duração da visita e páginas visitadas.
                </p>
                  <p class="text-center">
                    <u>Dados sobre o dispositivo de acesso:</u> modelo, fabricante, sistema operacional, operadora de telefonia, tipo de navegador, tipo e velocidade da conexão.
                  </p></td>
                <td><p class="text-center">
                  Viabilizar o funcionamento adequado do site, armazenar suas preferências, permitir uma boa experiência de uso e cumprir com as nossas obrigações legais.
                </p></td>
              </tr>
              <tr>
                <td><p class="text-center"><em>Analytics</em></p></td>
                <td><p class="text-center">
                  <u>Dados de navegação</u>: dados coletados por meio de cookies ou device IDs, incluindo IP, data e hora de acesso, localização geográfica, tipo de navegador, duração da visita e páginas visitadas.
                </p></td>
                <td><p class="text-center">
                  Entender seu comportamento de navegação e como o site/aplicativo está sendo usado, para melhorar sua experiência como usuário. Os dados coletados são agregados e, sempre que possível,
                  anonimizados.<a href="https://www.comercialivaipora.com.br/#_msocom_1">[UG1]</a>
                </p></td>
              </tr>
              <tr>
                <td><p class="text-center">Envio de ofertas e publicidade</p></td>
                <td><p class="text-center"><u>Dados de cadastro:</u> nome, e-mail e telefone.</p></td>
                <td><p class="text-center">Direcionamento de ofertas e campanhas promocionais.</p></td>
              </tr>
              <tr>
                <td><p class="text-center">Comunicação com o cliente</p></td>
                <td><p class="text-center"><u>Dados de cadastro:</u> nome, e-mail e telefone.</p></td>
                <td><p class="text-center">Oferecimento e divulgação de novos serviços e parcerias que possam ser de interesse do cliente.</p></td>
              </tr>
              <tr>
                <td><p class="text-center">Clube de Fidelização</p></td>
                <td><p class="text-center"><u>Dados de cadastro:</u> e-mail, nome, telefone, CPF, data de nascimento e endereço.</p></td>
                <td><p class="text-center">Cadastro no programa de fidelidade da Comercial Ivaiporã.</p></td>
              </tr>
              <tr>
                <td><p class="text-center">Ações promocionais</p></td>
                <td><p class="text-center"><u>Dados de cadastro</u>: Nome, CPF, endereço, e-mail e telefone.</p></td>
                <td><p class="text-center">Entrar em contato com o ganhador do prêmio.</p></td>
              </tr>
              <tr>
                <td><p class="text-center">Segurança</p></td>
                <td><p class="text-center">Captação de imagem por câmeras de segurança.</p></td>
                <td><p class="text-center">Segurança física dos ambientes da Comercial Ivaiporã.</p></td>
              </tr>
              <tr>
                <td><p class="text-center">Canais de atendimento</p></td>
                <td><p class="text-center">Nesse processo, os dados coletados por nós variam caso o Cliente já seja cadastrado em nossos sistemas - situação em que é solicitado o CPF - ou se ele está fazendo um novo contato.
                  Nesta última hipótese, os dados tratados, em regra, são: nome completo, CPF, endereço,telefone e e-mail.</p></td>
                <td><p class="text-center">Atender às solicitações, reclamações, sugestões ou elogios dos Clientes.</p></td>
              </tr>
              <tr>
                <td><p class="text-center">Recrutamento e seleção de pessoal</p></td>
                <td><p class="text-center"><u>Dados de currículo</u>: nome, endereço, telefone, e-mail, histórico acadêmico, histórico profissional, competências, línguas entre outros que Você espontaneamente nos fornecer.</p></td>
                <td><p class="text-center">Recrutamento de novos integrantes, incluindo o contato por telefone ou e-mail para viabilizar a participação nos nossos processos seletivos.</p></td>
              </tr>
              <tr>
                <td><p class="text-center">Relacionamento com fornecedores e parceiros</p></td>
                <td><p class="text-center"><u>Dados de cadastro</u>: nome, endereço, telefone, e-mail, dados de documentos, inscrição no INSS, entre outros.</p></td>
                <td><p class="text-center">Gerenciamento de relacionamento comercial e operacionalização dos pagamentos aos nossos parceiros e fornecedores.</p></td>
              </tr>
              </tbody>
            </table>
          </div>

          <p/><p class="text-justify">
          Você será o único responsável pela precisão, veracidade e atualização dos Dados Pessoais que nos fornecer.
        </p>

          <p><strong>3.&nbsp;COM QUEM OS DADOS PESSOAIS SÃO COMPARTILHADOS</strong></p>

          <p class="text-justify">
            Os seus Dados Pessoais são muito importantes para nós, pois são parte essencial do nosso negócio - o qual, frisa-se, não é a venda de informações para terceiros. Compartilhamos os Dados
            Pessoais nas situações descritas a seguir:
          </p>

          <p class="text-justify">
            <strong>&bull; Parceiros e prestadores de serviços</strong>. Nós contratamos empresas e pessoas para realizarem determinados serviços para a sua melhor experiência conosco. Na medida do possível, sempre
            buscamos avaliar cuidadosamente nossos fornecedores e parceiros e firmar com eles obrigações contratuais de segurança da informação e proteção de Dados Pessoais. Quando Você utiliza os serviços de nossos
            parceiros (Empréstimo Pessoal, Seguros Prestamista, Programa de Fidelidade, dentre outros), Você também se submete às regras e políticas de privacidade das respectivas empresas.
          </p>

          <p class="text-justify">
            <strong>&bull; Autoridades públicas.</strong> Podemos compartilhar os Dados Pessoais com autoridades públicas que os solicite, inclusive com órgãos de fiscalização das atividades laborais ou de atividades
            financeiras, órgãos reguladores, órgãos do Poder Judiciário ou Autoridades Policiais.
          </p>

          <p class="text-justify">
            <strong>&bull; Transferência de negócios.</strong> Nós estamos constantemente desenvolvendo nosso negócio, de forma que podemos vender ou comprar subsidiárias, novas unidades de negócio e ativos em
            geral, a qualquer momento. Nestas operações, é comum que as informações e dados dos clientes façam parte dos ativos transferidos, desde que expressamente respeitadas as garantias desta Política.
          </p>

          <p class="text-justify">
            <strong>&bull; Para terceiros indicados por Você.</strong> Podemos compartilhar seus Dados Pessoais com terceiros que tenham sido indicados por Você, mediante a sua solicitação.
          </p>

          <p><strong>4.&nbsp;TRANSFERÊNCIA INTERNACIONAL DE DADOS</strong></p>

          <p class="text-justify">
            Quando utilizarmos serviços de hospedagem em nuvem localizados em outros países os seus Dados Pessoais poderão ser transferidos internacionalmente.
          </p>

          <p><strong>5.&nbsp;PRAZO DE ARMAZENAMENTO</strong></p>

          <p class="text-justify">
            Os seus Dados Pessoais serão armazenados pela Comercial Ivaiporã somente pelo tempo que for necessário para cumprir com as finalidades para as quais os coletamos, inclusive para fins de cumprimento
            de quaisquer obrigações legais, contratuais, de prestação de contas ou requisição de autoridades competentes.
          </p>

          <p class="text-justify">
            Os prazos são definidos pela Comercial Ivaiporã de acordo com as características específicas de cada tratamento, incluindo a natureza dos dados, as finalidades para o tratamento, as obrigações legais ou
            regulatórias existentes, entre outros critérios.
          </p>


          <p><strong>6.&nbsp;COOKIES</strong></p>

          <p class="text-justify">
            Para customizar a experiência dos usuários e para gerar informações relevantes sobre a forma de uso deste site, a Comercial Ivaiporã poderá fazer o uso dos seguintes Cookies:
          </p>

          <p class="text-justify">
            <strong>&bull; Cookies estritamente necessários</strong> para viabilizar o funcionamento adequado do site;
          </p>

          <p class="text-justify">
            <strong>&bull; Cookies preferenciais</strong> para armazenar suas preferências e permitir uma boa experiência de uso;
          </p>

          <p class="text-justify">
            <strong>&bull; Cookies de análise</strong> para melhorar a qualidade do site por meio da coleta de informações sobre quais páginas são mais acessadas, as páginas pouco acessadas
            e tempo de permanência no site, por exemplo.
          </p>

          <p class="text-justify">
            A maioria dos navegadores de internet contém informações sobre como rejeitar novos cookies, como receber notificações quando receber um novo cookie ou até mesmo como desabilitá-los.
            Ao desabilitar, bloquear ou de qualquer forma rejeitar os cookies, pode ser que Você não consiga utilizar determinadas funções de nosso site, principalmente as que exigem que Você esteja
            logado em sua Conta Comercial Ivaiporã.
          </p>

          <p class="text-justify">
            &bull; Como remover cookies no <a href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&amp;hl=pt-BR" target="_blank">Google Chrome</a>
          </p>

          <p class="text-justify">
            &bull; Como remover cookies no <a href="https://support.mozilla.org/pt-BR/kb/limpe-cookies-e-dados-de-sites-no-firefox" target="_blank">Mozilla Firefox</a>
          </p>

          <p class="text-justify">
            &bull; Como remover cookies no <a href="https://support.microsoft.com/pt-br/help/278835/how-to-delete-cookie-files-in-internet-explorer" target="_blank">Internet Explorer</a>
          </p>

          <p class="text-justify">
            &bull; Como remover cookies no <a href="https://support.apple.com/pt-br/HT201265" target="_blank">Safari</a><a href="https://www.comercialivaipora.com.br/#_msocom_2">[UG2]</a>
          </p>


          <p><strong>7.&nbsp;COMO NÓS PROTEGEMOS OS SEUS DADOS</strong></p>

          <p class="text-justify">
            A Comercial Ivaiporã adota medidas técnicas e administrativas, pautadas nas boas práticas do mercado, para proteger os seus Dados Pessoais contra acessos não autorizados, perda, roubo,
            divulgação,alteração e qualquer outra modalidade de tratamento inadequado ou indevido.
          </p>

          <p class="text-justify">
            Todos os Dados Pessoais coletados serão armazenados em ambiente seguro, observado o estado da técnica disponível e criptografados. No entanto, considerando que nenhum sistema de segurança
            é absolutamente seguro, nós infelizmente não podemos garantir que não ocorrerão apreensões, interceptações e/ou violações nos nossos sistemas e bases de dados, e, por isso, a Comercial
            Ivaiporã se exime de quaisquer responsabilidades por eventuais danos e/ou prejuízos decorrentes de falhas, vírus ou invasões.
          </p>

          <p><strong>8.&nbsp;VOCÊ NO CONTROLE DOS SEUS DADOS - CONHEÇA OS SEUS DIREITOS</strong></p>

          <p class="text-justify">
            A Comercial Ivaiporã está comprometida com o cumprimento dos direitos que a legislação concede à Você, na qualidade de titular de Dados Pessoais. Abaixo, indicaremos quais são eles e como Você pode
            exercê-los junto ao Comercial Ivaiporã, a qualquer tempo:
          </p>

          <p class="text-justify">
            <strong>&bull; Confirmação</strong> da existência de atividades de tratamento de Dados Pessoais;
          </p>

          <p><strong>&bull; Acesso</strong> aos Dados Pessoais;</p>

          <p class="text-justify">
            <strong>&bull; Retificação</strong> de Dados Pessoais que estejam incompletos, inexatos ou desatualizados;
          </p>

          <p class="text-justify">
            <strong>&bull; Anonimização, bloqueio ou exclusão</strong >, nos casos em que forem tratados dados desnecessários, excessivos ou em desconformidade com a legislação;
          </p>

          <p class="text-justify">
            <strong>&bull; Portabilidade</strong> dos Dados Pessoais direto à Você ou a terceiros que escolher;
          </p>

          <p class="text-justify">
            <strong>&bull; Exclusão de dados tratados com consentimento</strong>, nos casos em que essa for a base legal para uma determinada atividade de tratamento, ressalvadas as possibilidades de
            continuarmos armazenando os dados quando for necessário para o cumprimento de uma obrigação legal ou regulatória, ou para exercício regular dos direitos da Comercial Ivaiporã;
          </p>

          <p class="text-justify">
            <strong>&bull; Revogação de consentimento</strong>, para quando Você tiver nos fornecido consentimento. Porém, essa revogação não afetará a legalidade das atividades realizadas até então;
          </p>

          <p class="text-justify">
            <strong>&bull; Oposição ao tratamento</strong>, nos casos em que o consentimento não for a base legal aplicável ao tratamento dos Dados Pessoais ou quando houver algum descumprimento à
            legislação. A Comercial Ivaiporã avaliará se a sua oposição é justificada ou não e adotará as medidas necessárias para suspender o tratamento, quando cabível e viável.
          </p>

          <p class="text-justify">
            Para exercê-los, basta enviar a sua solicitação para o e-mail <a href="mailto:compliancelgpd@comercialivaipora.com.br"><em>compliancelgpd@comercialivaipora.com.br</em></a><em>.</em>
          </p>

          <p class="text-justify">
            Atenderemos às suas solicitações dentro de um prazo razoável, em conformidade com a legislação aplicável, lembrando que, em alguns casos, poderemos te responder apenas após confirmarmos a sua
            identidade.
          </p>

          <p class="text-justify">
            A fim de garantir sua segurança e evitar fraudes, a Comercial Ivaiporã poderá solicitar informações ou comprovações necessárias para que seus Dados Pessoais não sejam compartilhados com quem não tenha
            autorização para tanto.
          </p>

          <p><strong>9.&nbsp;CONTATO</strong></p>


          <p class="text-justify">
            Em caso de qualquer dúvida em relação a esta
            Política ou solicitações para o cumprimento de
            seus direitos, Você ou seu responsável legal
            poderão entrar em contato pelo e-mail <a
              href="mailto:compliancelgpd@comercialivaipora.com.br"
          ><em>compliancelgpd@comercialivaipora.com.br</em></a
          ><em>.</em>
          </p>

          <p><strong>10.&nbsp;ALTERAÇÕES DA POLÍTICA</strong></p>

          <p class="text-justify">
            Nós buscamos oferecer os nossos Serviços com
            excelência e com a maior eficiência possível. Por
            isso, esta Política pode ser atualizada a qualquer tempo,
            cabendo à Você verificá-la sempre que
            possível por meio deste site.
          </p>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-col cols="12" class="py-3">
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  class="py-1"
              >
                <v-btn width="100%" small class="btn-lg btn-block" outlined text color="red" to="/login/sair">
                  Recusar termos e sair do sistema
                </v-btn>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  class="py-1"
              >
                <v-btn width="100%" small class="btn-lg btn-block" color="success" @click="confirmarPolitica()">
                  declaro que li e aceito os termos
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
    name: "DialogTermos",
    data() {
        return {
            carregando: false,
            dialogV: true,
            erro: "",
            msg: "",
        };
    },
    computed: {
        ...mapState(["backendUrl", "usuario", "sessaoChave"]),
    },
    methods: {
        confirmarPolitica() {
        this.erro = "";
        this.msg = "";
        this.carregando = true;
        return axios
        .post(`${this.backendUrl}login/aceitepolitica`,{
        })
        .then((res) => {
            console.log(res);
            let usuario = this.usuario;
            usuario.aceitepolitica = true;
            localStorage.setItem(this.sessaoChave, JSON.stringify(usuario));
            this.dialogV = false;
            this.carregando = false;
        })
        .catch(() => {
            this.carregando = false;
        });
        },
    },
    };
</script>

<style>
    .texto-dialog{
        font-size: small;
    }

    .fill-height {
      height: 100vh; /* Ajuste conforme necessário */
    }


    .custom-card-text {
      height: calc(100vh - 400px) !important; /* Ajuste o valor de 100px conforme necessário */
      overflow-y: auto !important;
    }

    .custom-card-text2 {
      height: calc(100vh - 300px) !important; /* Ajuste o valor de 100px conforme necessário */
      overflow-y: auto !important;
    }

    .v-dialog__content--active {
      overflow: hidden !important;
    }
</style>