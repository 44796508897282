<template>
    <v-dialog v-model="dialog" persistent>
        <v-card outlined max-width="575">
            <v-card-title class="text-h5">Alterar senha</v-card-title>
            <v-card-subtitle
                class="mt-0"
            >Defina sua nova senha, ela será necessária em seus próximos acessos.</v-card-subtitle>
            <v-card-text class="pb-0">
                <v-divider></v-divider>
                <v-form>
                    <v-row>
                        <v-col cols="12" class="mt-1 mb-n8">
                            <v-text-field
                                label="Senha"
                                type="password"
                                v-model="senha"
                                required
                                autocomplete="off"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Confirmar Senha"
                                type="password"
                                v-model="confirmarSenha"
                                required
                                autocomplete="off"
                            ></v-text-field>
                        </v-col>
                        <v-col v-if="erro" cols="12">
                            <v-alert
                                v-if="erro"
                                icon="mdi-alert-circle-outline"
                                close-icon="mdi-close"
                                type="error"
                                class="mb-0"
                            >{{erro}}</v-alert>
                            <v-alert
                                v-if="msg"
                                dismissible
                                icon="mdi-check"
                                close-icon="mdi-close"
                                type="success"
                                class="mb-0"
                            >{{msg}}</v-alert>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="redefinirSenha()">Confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
    name: "LoginDialog",
    data: () => ({
        carregando: false,
        dialog: true,
        senha: "",
        confirmarSenha: "",
        erro: "",
        msg: "",
    }),
    computed: {
        ...mapState(["backendUrl", "usuario", "sessaoChave"]),
    },
    methods: {
        redefinirSenha() {
            this.erro = "";
            this.msg = "";
            this.carregando = true;
            if (this.senha == this.confirmarSenha) {
                return axios
                    .post(`${this.backendUrl}usuario/alterarsenha`, {
                        token: this.usuario.token,
                        novasenha: this.senha,
                    })
                    .then((res) => {
                        console.log(res);
                        let usuario = this.usuario;
                        usuario.flagprimeiroacesso = false;
                        localStorage.setItem(
                            this.sessaoChave,
                            JSON.stringify(usuario)
                        );
                        this.dialog = false;
                        this.carregando = false;
                    })
                    .catch((e) => {
                        this.erro = this.erroFormatar(e);
                        this.carregando = false;
                    });
            } else {
                this.erro = "A confirmação de senha informada não confere!";
                this.carregando = false;
            }
        },
    },
};
</script>