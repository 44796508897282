<template>
    <v-menu bottom min-width="200px" rounded :close-on-content-click="true" offset-y>
        <template v-slot:activator="{ on }">
            <v-btn class="hidden-sm-and-down mx-3" icon v-on="on" @mouseenter="init">
                <v-avatar :color="cores[posicao]" size="48">
                    <span style="color: #FFF;" class="font-weight-bold">{{usuario.nome.substr(0,1)}}</span>
                </v-avatar>
            </v-btn>
        </template>
        <v-card>
            <v-list-item-content class="justify-center">
                <div class="mx-auto text-center">
                    <v-avatar :color="cores[posicao]" size="48">
                        <span
                            style="color: #FFF;"
                            class="font-weight-bold"
                        >{{usuario.nome.substr(0,1)}}</span>
                    </v-avatar>
                    <h3 class="my-3">{{ usuario.nome.split(' ')[0] }}</h3>
                    <v-divider class="my-3" />
                    <v-btn text block to="/perfil" exact tile>Meu Perfil</v-btn>
                    <v-divider class="my-3" />
                    <v-btn
                        depressed
                        rounded
                        class="font-weight-bold"
                        color="primary"
                        to="/login/sair"
                    >Sair</v-btn>
                </div>
            </v-list-item-content>
        </v-card>
    </v-menu>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
    name: "Avatar",
    data: () => ({
        cores: [
            "accent",
            "red",
            "green",
            "blue",
            "orange",
            "purple",
            "indigo",
            "deep-purple",
        ],
        posicao: 0,
    }),
    methods: {
        ...mapMutations(["uLogout"]),
        init() {
            this.posicao = Math.floor(Math.random() * this.cores.length);
        }
    },
    computed: {
        ...mapState(["usuario"]),
    },
};
</script>