import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let title = ' | Gestor - Comercial Ivaiporã'

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: 'Login',
            props : true,
            component: () => import('./Components/Login/Login'),
            meta : {
                title : 'Login' +  title
            }
        },
        {
            path: '/login/sair',
            name: 'LoginSair',
            props : true,
            component: () => import('./Components/Login/Sair'),
            meta : {
                title :'Sair' +  title
            }
        },
        // {
        //     path: '/',
        //     name: 'Inicio',
        //     props : true,
        //     component: () => import('./Components/Inicio/Inicio'),
        //     meta : {
        //         title : 'Início' +  title
        //     }
        // },
        {
            path: '/',
            props : true,
            component: () => import('./Components/Inicio/InicioContainer'),
            children: [
                {
                    path: '',
                    name: 'Inicio',
                    component: () => import('./Components/Inicio/Inicio'),
                    meta : {
                        title : 'Início' + title,
                        breadcrumb : [
                            {
                                text: 'Ínicio',
                                exact: true,
                                link: true,
                                disabled: false,
                                to: '/',
                            }
                        ]
                    }
                },
                ]
            },
        // {
        //     path: '/contrato',
        //     props : true,
        //     component: () => import('./Components/Contrato/ContratoContainer'),
        //     children: [
        //         {
        //             path: '',
        //             name: 'ContratoLista',
        //             component: () => import('./Components/Contrato/ContratoLista'),
        //             meta : {
        //                 title : 'Meus Contratos' + title,
        //                 permission : 'contrato_listar',
        //                 breadcrumb : [
        //                     {
        //                         text: 'Ínicio',
        //                         exact: true,
        //                         link: true,
        //                         disabled: false,
        //                         to: '/',
        //                     },
        //                     {
        //                         text: 'Meus contratos',
        //                         exact: true,
        //                         link: true,
        //                         disabled: true,
        //                         to: '/contrato',
        //                     }
        //                 ]
        //             }
        //         },
                // {
                //     path: 'dashboard',
                //     name: 'ContratoDashboard',
                //     component: () => import('./Components/Contrato/ContratoDashboard'),
                //     meta : {
                //         title : 'Dashboard' + title,
                //         permission : 'contrato_dashboard',
                //         breadcrumb : [
                //             {
                //                 text: 'Ínicio',
                //                 exact: true,
                //                 link: true,
                //                 disabled: false,
                //                 to: '/',
                //             },
                //             {
                //                 text: 'Meus contratos',
                //                 exact: true,
                //                 link: true,
                //                 disabled: false,
                //                 to: '/contrato',
                //             },
                //             {
                //                 text: 'Dashboard',
                //                 exact: true,
                //                 link: true,
                //                 disabled: true,
                //                 to: '',
                //             }
                //         ]
                //     }
                // }
        //     ]
        // },
        // {
        //     path: '/entrega',
        //     props : true,
        //     component: () => import('./Components/Entrega/EntregaContainer'),
        //     children: [
        //         {
        //             path: '',
        //             name: 'EntregaLista',
        //             component: () => import('./Components/Entrega/EntregaLista'),
        //             meta : {
        //                 title : 'Minhas Entregas' + title,
        //                 permission : 'entrega_listar',
        //                 breadcrumb : [
        //                     {
        //                         text: 'Ínicio',
        //                         exact: true,
        //                         link: true,
        //                         disabled: false,
        //                         to: '/',
        //                     },
        //                     {
        //                         text: 'Minhas entregas',
        //                         exact: true,
        //                         link: true,
        //                         disabled: true,
        //                         to: '/entrega',
        //                     }
        //                 ]
        //             }
        //         },
        //         {
        //             path: 'dashboard',
        //             name: 'EntregaDashboard',
        //             component: () => import('./Components/Entrega/EntregaDashboard'),
        //             meta : {
        //                 title : 'Dashboard' + title,
        //                 permission : 'entrega_dashboard',
        //                 breadcrumb : [
        //                     {
        //                         text: 'Ínicio',
        //                         exact: true,
        //                         link: true,
        //                         disabled: false,
        //                         to: '/',
        //                     },
        //                     {
        //                         text: 'Minhas entregas',
        //                         exact: true,
        //                         link: true,
        //                         disabled: false,
        //                         to: '/entrega',
        //                     },
        //                     {
        //                         text: 'Dashboard',
        //                         exact: true,
        //                         link: true,
        //                         disabled: true,
        //                         to: '',
        //                     }
        //                 ]
        //             }
        //         }
        //     ]
        // },
        /* {
            path: '/pedido',
            props : true,
            component: () => import('./Components/Pedido/PedidoContainer'),
            children: [
                {
                    path: '',
                    name: 'PedidoLista',
                    component: () => import('./Components/Pedido/PedidoLista'),
                    meta : {
                        title : 'Meus Pedidos' + title,
                        permission : 'pedido_listar',
                        breadcrumb : [
                            {
                                text: 'Ínicio',
                                exact: true,
                                link: true,
                                disabled: false,
                                to: '/',
                            },
                            {
                                text: 'Meus pedidos',
                                exact: true,
                                link: true,
                                disabled: true,
                                to: '/pedido',
                            }
                        ]
                    }
                },
                {
                    path: 'dashboard',
                    name: 'PedidoDashboard',
                    component: () => import('./Components/Pedido/PedidoDashboard'),
                    meta : {
                        title : 'Dashboard' + title,
                        permission : 'pedido_dashboard',
                        breadcrumb : [
                            {
                                text: 'Ínicio',
                                exact: true,
                                link: true,
                                disabled: false,
                                to: '/',
                            },
                            {
                                text: 'Meus pedidos',
                                exact: true,
                                link: true,
                                disabled: false,
                                to: '/pedido',
                            },
                            {
                                text: 'Dashboard',
                                exact: true,
                                link: true,
                                disabled: true,
                                to: '',
                            }
                        ]
                    }
                },
                {
                    path: 'get/:idpedido',
                    props : true,
                    name: 'PedidoGet',
                    component: () => import('./Components/Pedido/PedidoGet'),
                    meta : {
                        title : 'Pedido' + title,
                        permission : 'pedido_listar',
                        breadcrumb : [
                            {
                                text: 'Ínicio',
                                exact: true,
                                link: true,
                                disabled: false,
                                to: '/',
                            },
                            {
                                text: 'Meus pedidos',
                                exact: true,
                                link: true,
                                disabled: false,
                                to: '/pedido',
                            },
                            {
                                text: 'Pedido',
                                exact: true,
                                link: true,
                                disabled: true,
                                to: '',
                            }
                        ]
                    }
                }
            ]
        }, */
        {
            path: '/perfil',
            props : true,
            component: () => import('./Components/Perfil/PerfilContainer'),
            children: [
                {
                    path: '',
                    name: 'Perfil',
                    component: () => import('./Components/Perfil/Perfil'),
                    meta : {
                        title : 'Meu Perfil' + title,
                        permission : 'usuario_listar',
                        breadcrumb : [
                            {
                                text: 'Ínicio',
                                exact: true,
                                link: true,
                                disabled: false,
                                to: '/',
                            },
                            {
                                text: 'Meu Perfil',
                                exact: true,
                                link: true,
                                disabled: true,
                                to: '/perfil',
                            }
                        ]
                    }
                },
            ]
        },
        {
            path: '*',
            component: () => import('./Components/Erro/404'),
            meta : {
                title : 'Início' + title,
            },
        }
    ]
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title == title ? title.replace(' | ', '') : to.meta.title
    
    /* if (to.path != '/' && to.path != '/login' && to.path != '/login/sair') {
        let usuarioSessao = JSON.parse(localStorage.getItem('comercialivaipora_gestor'))
        let usuarioPerm = usuarioSessao.permissao.filter((p) => {
            return p.idpermissao == to.meta.permission
        }).length > 0
        if (usuarioPerm) {
            next()
        } else {
            next({ path: '/' })
        } 
    } else {
        next()  
    } */

    next()
})

// router.afterEach((to) => {
router.afterEach(() => {
    /*
    // eslint-disable-next-line
    gtag('event', 'page_view', {
        send_to    : 'G-XXXXXXXXXXXX',
        // eslint-disable-next-line
        page_path  : '/' + to.path,
        page_title : document.title
    })
    */
})

export default router