import Vue from "vue";
import axios from "axios";
import Vuex from "vuex";

Vue.filter('formataDinheiro', function(v) {
    return parseFloat(v).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
})

Vue.filter('diferencaDias', function(d) {
    var dataAtual = new Date()  
    var dataContrato = new Date(d.split('/').reverse().join('-'))
    var diferenca = Math.abs(dataAtual.getTime() - dataContrato.getTime())
    var dias = Math.ceil(diferenca / (1000 * 60 * 60 * 24))
    return (dias - 1)
})

Vue.filter('diferencaDiasBar', function(d) {
    var dataAtual = new Date()  
    var dataContrato = new Date(d.split('/').reverse().join('-'))
    var diferenca = Math.abs(dataAtual.getTime() - dataContrato.getTime())
    var dias = Math.ceil(diferenca / (1000 * 60 * 60 * 24))
    return (dias - 1) * 100 / 90
})

Vue.filter('colorBar', function(d) {
    var dataAtual = new Date()  
    var dataContrato = new Date(d.split('/').reverse().join('-'))
    var diferenca = Math.abs(dataAtual.getTime() - dataContrato.getTime())
    var dias = Math.ceil(diferenca / (1000 * 60 * 60 * 24))
    if (((dias - 1) * 100 / 90) <= 50) {
        return 'green'
    } else if (((dias - 1) * 100 / 90) > 50 && ((dias - 1) * 100 / 90) < 80) {
        return 'amber'
    } else {
        return 'red'
    }
})

Vue.filter('formataCep', function(cep) {
    return cep.substr(0,5) + '-' + cep.substr(5)
})

Vue.filter('formataContato', function(c) {
    if (c.length == 11) {
        return '(' + c.substr(0,2) + ') ' + c.substr(2, 5) + '-' + c.substr(7)
    } else if (c.length == 10) {
        return '(' + c.substr(0,2) + ') ' + c.substr(2, 4) + '-' + c.substr(6)
    } else {
        return c
    }    
})

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        backendUrl : `${process.env.VUE_APP_BACKEND_URL}:${process.env.VUE_APP_BACKEND_PORT}/`,
        sessaoChave : 'comercialivaipora_gestor',
        usuario: {},
        pgLimit : 20,
        logado : false,
    },
    mutations:{
        uLogar(state, usuario) {
            state.usuario = usuario
            if (usuario) {
                axios.defaults.headers.common['token'] = usuario.token;
                state.logado = true;
            } else {
                delete axios.defaults.headers.common['token'];
                state.logado = false;
            }
        }
    },
    actions:{}
})